import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["alert"]

  alertTargetConnected(e) {
    setTimeout(() => e.classList.add("alert-show"), 0);
    setTimeout(() => e.classList.remove("alert-show"), 2500);
  }
}
